import React from "react"
import { graphql } from "gatsby"
import { useLayoutContext } from "~layout/contexts/layout"
import { Box } from "~common/components/Primitives"
import Page from "~common/components/Page"
import PostPreview from "~blog/components/PostPreview"
import Separator from "~blog/components/Separator"

const Blog = ({ data }) => {
  const { edges: posts } = data.allMdx

  const { headerHeight } = useLayoutContext()

  return (
    <Page
      seo={{ title: "Blog", description: "Almagesto Astronomy & Design Blog" }}
      sx={{
        minHeight: "100vh",
        pt: headerHeight,
        px: 0,
      }}
    >
      <Page.Body sx={{ m: 0, p: 0 }}>
        {posts.map(({ node: post }, i) => {
          const { id } = post
          return (
            <Box key={id}>
              <PostPreview post={post} />
              {i !== posts.length - 1 && (
                <Separator color="softened-secondary" sx={{ maxWidth: 640 }} />
              )}
            </Box>
          )
        })}
      </Page.Body>
    </Page>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allMdx(
      filter: {
        fields: { source: { eq: "posts" } }
        frontmatter: { published: { eq: true } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 10
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            path
            title
            date(formatString: "MMMM DD, YYYY")
            author
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`

export default Blog
