import React from "react"
import { Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"

const PostPreview = ({ post, sx, ...rest }) => {
  const { frontmatter, excerpt } = post
  const { path, title, date, author, featuredImage } = frontmatter
  return (
    <Box
      as="article"
      sx={{
        maxWidth: [640, 680, 720, 780],
        mx: [0, "auto"],
        my: [4, 5],
        p: [4, 4, 4, 5],
        "&:hover": {
          outline: [
            "none",
            "none",
            theme => `1px dashed ${theme.colors["softened-accent"]}`,
          ],
          boxShadow: [
            "none",
            "none",
            theme => `0px 0px 16px ${theme.colors["softened-secondary"]}`,
          ],
        },
        ...sx,
      }}
      {...rest}
    >
      <Link to={path}>
        <Typography as="h1" variant="heading2" sx={{ color: "accent", mx: 0 }}>
          {title}
        </Typography>
        <GatsbyImage image={getImage(featuredImage)} alt={title} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mx: 0,
            py: [3, 4],
          }}
        >
          <Typography
            as="span"
            variant="capitalized3"
            sx={{ color: "softened-secondary" }}
          >
            {date}
          </Typography>
          <Typography
            as="span"
            variant="small"
            sx={{ color: "softened-accent", mx: [3, 4] }}
          >
            |
          </Typography>
          <Typography as="span" variant="heading4" sx={{ color: "secondary" }}>
            {author}
          </Typography>
        </Box>
        <Typography
          as="p"
          variant="normal"
          sx={{ color: "secondary", mx: 0, py: [4] }}
        >
          {excerpt}
        </Typography>
        <Typography
          variant="normal"
          sx={{
            mx: 0,
            pb: [4],
            color: "accent",
            fontWeight: 500,
            textAlign: "right",
            "&:hover": { color: "softened-secondary" },
          }}
        >
          Continue reading &#8620;
        </Typography>
      </Link>
    </Box>
  )
}

export default PostPreview
