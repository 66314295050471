import React from "react"
import { Box } from "~common/components/Primitives"
import Line from "~common/components/Line"
import Logo from "~common/components/Logo"

const Separator = ({ color = "secondary", sx, ...rest }) => (
  <Box
    sx={{
      mx: "auto",
      my: [4, 5],
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...sx,
    }}
    {...rest}
  >
    <Line color={color} align="end" sx={{ width: "25%" }} />
    <Logo
      color={color}
      sx={{ width: [48, 48, 56], height: [48, 48, 56], mx: [2, 3] }}
    />
    <Line color={color} align="start" sx={{ width: "25%" }} />
  </Box>
)

export default Separator
